<template>
  <ul class="menu-container list-unstyled w-100 mb-0">
    <li :class="$style.item" v-for="item in menus" :key="item.key">
      <template v-if="!item.sub">
        <a
          href="#"
          @click.prevent="menuChange(item.key)"
          :class="$style.itemLink"
        >
          <div class="mr-3">
            <div
              class="menu"
              :class="menuActive === item.key ? $style.menuActive : ''"
            >
              {{ item.menu }}
            </div>
            <!-- <a-icon :type="item.icon" /> -->
          </div>
          <div :class="$style.itemAction">
            <span />
          </div>
        </a>
      </template>
      <template v-else>
        <a
          href="#"
          @click.prevent="toggleSubMenu(item)"
          :class="$style.itemLink"
        >
          <div class="mr-3">
            <div
              :class="
                isParentOfSubActive(item) ? 'font-weight-bold text-primary' : ''
              "
            >
              {{ item.menu }}
            </div>
          </div>
          <div
            :class="
              openSubMenuKey.includes(item.key)
                ? $style.itemActionDown
                : $style.itemAction
            "
          >
            <span />
          </div>
        </a>
        <transition name="slide-fadein-down" mode="out-in">
          <ul v-if="openSubMenuKey.includes(item.key)" class="list-unstyled ml-4">
            <li
              :class="$style.item"
              v-for="submenu in item.children"
              :key="submenu.key"
              @click.prevent="menuChange(submenu.key)"
            >
              <a href="#" :class="$style.itemLink">
                <div class="mr-3">
                  <div
                    class="menu"
                    :class="menuActive === submenu.key ? $style.menuActive : ''"
                  >
                    {{ submenu.menu }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </transition>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      required: true,
    },
    menuActive: {
      // type: Number,
      required: true,
    },
  },
  data() {
    return {
      openSubMenu: false,
      openSubMenuKey: [],
    }
  },
  methods: {
    menuChange(value) {
      this.$emit('menu-change', value)
    },
    toggleSubMenu(item) {
      // console.log(e)
      this.openSubMenu = !this.openSubMenu
      const isExist = this.openSubMenuKey.includes(item.key)

      if (!isExist) this.openSubMenuKey.push(item.key)
      else this.openSubMenuKey = this.openSubMenuKey.filter(menu => menu !== item.key)

      // this.openSubMenuKey = this.openSubMenu ? item.key : null
    },
    isParentOfSubActive(item) {
      return Boolean(item.children.find(children => children.key === this.menuActive))
    },
  },
  mounted() {
    this.menus.forEach(menu => {
      if (menu.sub) {
        if (this.isParentOfSubActive(menu)) this.openSubMenuKey.push(menu.key)
      }
    })
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
